<template>
  <div class="customize-main">
    <table class="customize-form" border="0" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td class="text-center">费用期间<br />Account Date(yyyy/mm/dd)</td>
          <td class="text-center" colspan="2">费用项目<br />Cost Category</td>
          <td class="text-center" colspan="2">
            费用子项<br />Cost Sub-category
          </td>
          <td class="text-center" colspan="7">
            费用具体描述<br />Descriptions of Cost
          </td>
        </tr>
        <tr>
          <td class="text-center">
            <el-date-picker
                v-model="Duration"
                value-format="yyyy-MM"
                type="month"
                placeholder="选择月"
              >
              </el-date-picker
          </td>
          <td class="text-center" colspan="2">
             <el-select
              v-model="CostCategory"
              @change="changeCostCategory"
              placeholder="请选择"
              filterable
              clearable
            >
              <el-option
                v-for="item in costCategoryList1"
                :key="item.ID"
                :label="item.Name"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center" colspan="2">
             <el-select
              v-model="SubCostCategory"
              placeholder="请选择"
              filterable
              clearable
            >
              <el-option
                v-for="item in costCategoryList2"
                :key="item.ID"
                :label="item.Name"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center" colspan="7">
            <el-input v-model="Description " />
          </td>
        </tr>
        <tr>
          <td class="text-center">
            项目名称<br />
            Project Name
          </td>
          <td class="text-center">
            项目简称<br />
            Short Project Name
          </td>
          <td class="text-center">
            分项目名称<br />
            Sub Project Name
          </td>
          <td class="text-center">
            用工单位<br />
            Working Unit
          </td>
          <td class="text-center">
            用工部门<br />
            Working Department
          </td>
          <td class="text-center">
            成本中心<br />
            Cost center
          </td>
          <td class="text-center">
            成本中心编号<br />
            Cost center NO.
          </td>
          <td class="text-center">
            人数<br />
            Qties
          </td>
          <td class="text-center">
            分割金额<br />
            Allocation Amount(SAR)
          </td>
          <td class="text-center">
            实际成本<br />
            Actual Cost
          </td>
          <td class="text-center">
            差额（收入）<br />
            Difference/Profits
          </td>
          <td class="text-center">
            备注<br />
            Remark
          </td>
        </tr>
        <tr v-for="(item, index) in CostAllocationDetails" :key="index">
          <td class="text-center">
            <el-select
              v-model="item.ProjectID"
              @change="getCostCenters(index,2)"
              placeholder="请选择"
              filterable
              clearable
            >
              <el-option
                v-for="item in projectList"
                :key="item.ProjectID"
                :label="item.ProjectName"
                :value="item.ProjectID"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center">
     {{item.ProjectNameEN}}
          </td>
          <td class="text-center">
            <el-select
              v-model="item.SubProjectID"
              v-if="item.SubProjeList.length >= 1"
              placeholder="请选择"
              clearable
              filterable
            >
              <el-option
                v-for="item in item.SubProjeList"
                :key="item.ProjectID"
                :label="item.ProjectName"
                :value="item.ProjectID"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center">
            <el-select
              v-model="item.CompanyID"
              @change="getCostCenters(index)"
              placeholder="请选择"
              filterable
              clearable
            >
              <el-option
                v-for="item in companyList"
                :key="item.ID"
                :label="item.CompanyCHS"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center">
            <el-select
              v-model="item.DepartmentID"
              @change="getCostCenters(index)"
              placeholder="请选择"
              filterable
              clearable
            >
              <el-option
                v-for="item in departmentList"
                :key="item.ID"
                :label="item.DepartmentCHS"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center">
            <el-select
              v-model="item.CostCenterID"
              placeholder="请选择"
              @change="getCostCenterCode(index)"
              v-if="item.CostCenterList.length > 1"
              filterable
              clearable
            >
              <el-option
                v-for="cItem in item.CostCenterList"
                :key="cItem.Id"
                :label="cItem.Name ? cItem.Name : '未填写'"
                :value="cItem.Id"
              >
              </el-option>
            </el-select>
            <span v-if="item.CostCenterList.length === 1">{{
              item.CostCenterList[0].Name ? item.CostCenterList[0].Name : ""
            }}</span>
          </td>
          <td class="text-center">{{ item.CostCenterCode }}</td>
          <td class="text-center">
            <el-input-number
              v-model="item.Qties"
              :min="0"
              label="请输入"
            ></el-input-number>
          </td>
          <td class="text-center">
            <el-input
              Onkeyup="this.value=this.value.replace(/[^\-?\d.]/g,'').replace(/^0+(\d)/,'$1').replace(/^-+/,'-').replace(/^\./,'0.').replace(/^(\\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');this.dispatchEvent(new Event('input'))"
              v-model.trim="item.AllocationAmount"
              placeholder="请输入"
            />
          </td>
          <td class="text-center">
            <el-input
              Onkeyup="this.value=this.value.replace(/[^\d^\.]+/g,'').replace(/^0+(\d)/,'$1').replace(/^\./,'0.').match(/\d+.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
              v-model.trim="item.ActualCost"
              placeholder="请输入"
            />
          </td>
          <td class="text-center">
            <el-input
              Onkeyup="this.value=this.value.replace(/[^\d^\.]+/g,'').replace(/^0+(\d)/,'$1').replace(/^\./,'0.').match(/\d+.?\d{0,2}/);this.dispatchEvent(new Event('input'))"
              v-model.trim="item.Difference"
              placeholder="请输入"
            />
          </td>

          <td class="text-center">
            <el-select
              v-model="item.Remark"
              placeholder="请选择"
              filterable
              clearable
            >
              <el-option
                v-for="item in CostAllocationType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { getNewDate } from "@/utils/date";
import {
  editCostAllocation,
  allCostCenters,
  getProjectAll,
  getCompanyAll,
  getDepartmentAll,
  getProjectsChildren,
  getcostCategoryRoot,
  getcostCategorychildren,
  addcost,
  editcost,
} from "@/api/user";
export default {
  data() {
    return {
      ID: null,
      Duration: null,
      CostCategory: null,
      CostCategoryName: null,
      SubCostCategory: null,
      SubCostCategoryName: null,
      Description: null,
      GroupID: null,
      CostAllocationType: [
        {
          value: "1",
          label: "分摊（+）Allocation",
        },
        {
          value: "2",
          label: "返还（-）Refund",
        },
      ],
      SponsorList: [
        {
          label: "SNEI",
          value: 1,
        },
        {
          label: "SEG",
          value: 2,
        },
        {
          label: "其它",
          value: 3,
        },
      ],
      costAllocationItem: {
        ID: null,
        Sponsor: null,
        ProjectID: null,
        SubProjectID: null,
        CompanyID: null,
        DepartmentID: null,
        CostCenterID: null,
        CostCenterCode: null,
        Qties: null,
        AllocationAmount: null,
        ActualCost: null,
        Difference: null,
        Remark: null,
        ProjectNameEN: null,
        SubProjeList: [],
        CostCenterList: [],
      },
      CostAllocationDetails: [],
      projectList: [],
      companyList: [],
      departmentList: [],
      costCategoryList1: [],
      costCategoryList2: [],
    };
  },

  methods: {
    getDepartmentList(data1, data2, data3) {
      this.projectList = data1;
      this.companyList = data2;
      this.departmentList = data3;
      this.TableDate = getNewDate();
    },
    addCostAllocation() {
      const data = {
        ID: this.ID,
        Duration: this.Duration,
        CostCategory: this.CostCategory,
        CostCategoryName: this.CostCategoryName,
        SubCostCategory: this.SubCostCategory,
        SubCostCategoryName: this.SubCostCategoryName,
        Description: this.Description,
        GroupID: this.GroupID,
        Details: [],
      };
      this.CostAllocationDetails.forEach((item) => {
        if (item.ProjectID) {
          data.Details.push(JSON.parse(JSON.stringify(item)));
        }
      });
      if (!this.ID) {
        addcost(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("新建成功");
            this.$emit("update");
            this.$emit("close");
          }
        });
      } else {
        editcost(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("编辑成功");
            this.$emit("update");
            this.$emit("close");
          }
        });
      }
    },
    geteditData(params) {
      this.ID = params.ID;
      this.Duration = params.Duration;
      this.CostCategory = params.CostCategory;
      this.CostCategoryName = params.CostCategoryName;
      this.SubCostCategory = params.SubCostCategory;
      this.SubCostCategoryName = params.SubCostCategoryName;
      this.Description = params.Description;
      this.GroupID = params.GroupID;
      getcostCategorychildren({ id: this.CostCategory }).then((res) => {
        if (res.status === 200) {
          this.costCategoryList2 = res.response;
        } else {
          this.costCategoryList2 = [];
        }
      });
      this.CostAllocationDetails.forEach((_, index, Array) => {
        if (params.Details[index]) {
          params.Details[index].CostAllocationType =
            params.Details[index].TypeID;
          Array[index] = JSON.parse(JSON.stringify(params.Details[index]));
          Array[index].CostCenterList = [];
          Array[index].SubProjeList = [];
          Array[index].AllocationAmount = Array[index].Amount;
          allCostCenters({
            projectId: Array[index].ProjectID,
            companyId: Array[index].CompanyID,
            deptId: Array[index].DepartmentID,
          }).then((res) => {
            if (res.status === 200) {
              Array[index].CostCenterList = res.response;
              if (res.response.length === 1) {
                Array[index].CostCenterID = res.response[0].Id;
                Array[index].CostCenterCode = res.response[0].Code;
              }
            }
          });

          this.projectList.forEach((cItem) => {
            if (cItem.ProjectID === Array[index].ProjectID) {
              Array[index].ProjectNameEN = cItem.ProjectNameEN;
            }
          });
          this.getProjectsChildren(index, Array[index].ProjectID);
        }
      });
      this.CostAllocationDetails = this.CostAllocationDetails.concat();
    },
    clearData() {
      this.ID = null;
      this.Duration = null;
      this.CostCategory = null;
      this.CostCategoryName = null;
      this.SubCostCategory = null;
      this.SubCostCategoryName = null;
      this.Description = null;
      this.GroupID = null;
      this.CostAllocationDetails = [];
      for (let i = 0; i < 5; i++) {
        const costAllocationItem = JSON.parse(
          JSON.stringify(this.costAllocationItem)
        );
        this.CostAllocationDetails.push(costAllocationItem);
      }
    },
    getCostCenters(index, type) {
      const item = JSON.parse(
        JSON.stringify(this.CostAllocationDetails[index])
      );

      const data = {
        projectId: item.ProjectID,
        companyId: item.CompanyID,
        deptId: item.DepartmentID,
      };
      if (type === 2) {
        item.SubProjectID = null;
        if (item.ProjectID) {
          this.getProjectsChildren(index, item.ProjectID);
        } else {
          this.SubProjeList = null;
        }
        this.projectList.forEach((cItem) => {
          if (cItem.ProjectID === item.ProjectID) {
            item.ProjectNameEN = cItem.ProjectNameEN;
          }
        });
      }
      allCostCenters(data).then((res) => {
        if (res.status === 200) {
          item.CostCenterList = res.response;
          if (res.response.length === 0) {
            this.$message.warning("该选项下不存在成本中心，请重新选择");
          }
          if (res.response.length === 1) {
            item.CostCenterID = res.response[0].Id;
            item.CostCenterCode = res.response[0].Code;
          }
        }
      });
      this.CostAllocationDetails.splice(index, 1, item);
    },

    getProjectsChildren(index, id) {
      getProjectsChildren({ id }).then((res) => {
        console.log("res", res);
        if (res.status === 200) {
          this.CostAllocationDetails[index].SubProjeList = res.response;
        }
      });
    },
    getCostCenterCode(index) {
      const item = JSON.parse(
        JSON.stringify(this.CostAllocationDetails[index])
      );
      item.CostCenterList.forEach((cItem) => {
        if (cItem.Id === item.CostCenterID) {
          item.CostCenterCode = cItem.Code;
        }
      });

      this.CostAllocationDetails.splice(index, 1, item);
    },

    changeCostCategory() {
      this.costCategoryList1.forEach((item) => {
        if (item.ID === this.CostCategory) {
          this.CostCategoryName = item.Name;
        }
      });
      if (this.CostCategory) {
        getcostCategorychildren({ id: this.CostCategory }).then((res) => {
          if (res.status === 200) {
            this.costCategoryList2 = res.response;
          } else {
            this.costCategoryList2 = [];
            this.SubCostCategory = null;
          }
        });
      } else {
        this.costCategoryList2 = [];
        this.SubCostCategory = null;
      }
    },
  },
  created() {
    this.TableDate = getNewDate();
    for (let i = 0; i < 5; i++) {
      const costAllocationItem = JSON.parse(
        JSON.stringify(this.costAllocationItem)
      );
      this.CostAllocationDetails.push(costAllocationItem);
    }
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
    getDepartmentAll().then((res) => {
      if (res.status === 200) {
        this.departmentList = res.response;
      }
    });
    getcostCategoryRoot().then((res) => {
      if (res.status === 200) {
        this.costCategoryList1 = res.response;
        console.log("costCategoryList1", this.costCategoryList1);
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.customize-main {
  width: 100%;
  color: #000;
  .teding {
    max-width: 100%;
  }
  .customize-title1 {
    font-size: 30px;
    text-align: center;
    color: #000;
    font-weight: bolder;
  }
  .customize-herder {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 12px;
  }
  .customize-footer {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 24px;
    .text-box {
      text-align: center;
      div {
        margin-bottom: 8px;
      }
    }
  }
  .customize-form {
    font-size: 11px;
    width: 100%;
    border-left: 1px solid black;
    border-top: 1px solid black;
    margin-top: 12px;
    tr td {
      border-right: 1px solid black;
      border-bottom: 1px solid black;
    }
    .text-center {
      text-align: center;
      font-size: 13px;
      padding: 5px;
      .el-select {
        width: 100%;
      }
      .el-input {
        width: 100%;
      }
      .el-date-editor {
        width: 100%;
      }
    }
    .text-title {
      text-align: center;
      font-size: 13px;
      .title {
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #e0e0e0;
      }
      .img-box {
        padding: 10px;
        min-height: 100px;
      }
      .img-box >>> img {
        width: 100%;
      }
    }
    .text-right {
      text-align: right;
      font-size: 13px;
      padding: 5px;
    }
    .text-left {
      text-align: left;
      font-size: 10px;
      padding: 5px;
    }
  }
  .customize-form2 {
    border-top: none;
    margin-top: 0;
  }
}
</style>
<style scoped>
/* .customize-main >>> .el-input__inner { */
/* border: none; */
/* } */
.input-box >>> .el-input__inner {
  border: none;
  padding: 0;
}
.text-size >>> .el-checkbox__input {
  margin-right: 10px;
}
.signature {
  max-width: 100px;
}
.felx-box {
  display: flex;
  align-items: center;
}
.img-box {
  max-width: 100px;
  margin-left: 10px;
}
.img-box >>> img {
  width: 100%;
}
</style>