<template>
  <div class="main">
    <div class="page-box">
      <div class="page-title">费用处理</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breadcrumb-active"
          ><i class="fa fa-dashboard"></i>工作台</el-breadcrumb-item
        >
        <el-breadcrumb-item class="breadcrumb-active"
          >费用管理</el-breadcrumb-item
        >
        <el-breadcrumb-item class="breadcrumb-active"
          >费用管理</el-breadcrumb-item
        >
        <el-breadcrumb-item>费用处理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="card-box">
      <!-- 角色列表 -->
      <div class="c-left">
        <div class="title-box">
          <i class="el-icon-s-order"></i>
          <div class="name">费用项目</div>
        </div>
        <div class="role-list">
         <div class="role-item1" :style="`height:${item.arrow?item.height:34}px`" v-for="(item,index) in data" :key="index">
          <div :class="['role-title-box',{'role-title-box-active':active.length===1 && active[0]===index}]">
              <img @click="changeArrow(index)" :class="['arrow',{'arrow-active':item.arrow}]" src="@/assets/image/arrow.png" />
              <div class="name" :title="item.Name" @click="changeActive(index)">{{item.Name}}</div>
          </div>
          <!-- <div class="role-fa"> -->
          <div class="role-item2" v-for="(cItem,cIndex) in item.Children" :key="cIndex">
            <div  @click.stop="changeActive(index,cIndex)" :class="['role-title-box', 'role-title-box2',{'role-title-box-active':active.length===2 && active[0]===index && active[1]===cIndex}]">
              <div class="name" :title="cItem.Name">{{cItem.Name}}</div>
            </div>
          </div>
          <!-- </div> -->
         </div> 
        </div>
      </div>
       <div class="c-right">
            <div class="screen-box">
        <div class="screen-input-box">
              <div class="title-box">项目部:</div>
          <el-select v-model="projectId" class="card-select" placeholder="请选择项目部" filterable
              clearable @change="changeProjectId" >
            <el-option
              v-for="item in projectList"
              :key="item.ProjectID"
              :label="item.ProjectName"
              :value="item.ProjectID"
              
            >
            </el-option>
          </el-select>
    
        </div>
 <div class="screen-input-box" v-if="subProjectList.length!==0">
              <div class="title-box">分项目:</div>
          <el-select v-model="subProjectId" class="card-select" placeholder="请选择分项目" filterable
              clearable>
            <el-option
              v-for="item in subProjectList"
              :key="item.ProjectID"
              :label="item.ProjectName"
              :value="item.ProjectID"
            >
            </el-option>
          </el-select>
    
        </div>
    <div class="screen-input-box">
          <div class="title-box">用工单位:</div>
          <el-select v-model="companyId" class="card-select" placeholder="请选择单位" filterable
              clearable>
            <el-option
              v-for="item in companyList"
              :key="item.ID"
              :label="item.CompanyCHS"
              :value="item.ID"
            >
            </el-option>
          </el-select>
        </div>
         <div class="screen-input-box">
          <div class="title-box">签证类型:</div>
          <el-select v-model="sponsor" class="card-select" placeholder="请选择签证类型" filterable
              clearable>
            <el-option
              v-for="item in SponsorList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">费用期间:</div>
          <el-date-picker
            v-model="duration"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>

        <button class="btn-pre" @click="getCostsList(1)">
          <i class="fa fa-search"></i>查询
        </button>
        <button class="btn-pre btn-add" @click="newBuilt">
          <i class="fa ion-plus-round"></i>新建
        </button>
        <button class="btn-pre" @click="dialogVisible3=true"><i class="fa fa-upload"></i>导入</button>
        <button class="btn-pre" @click="getSaudiCostExport">
          <i class="fa fa-download"></i>导出
        </button>
      </div>
      <div class="table-box">
        <el-table
          :data="tableData"
          ref="table"
          border
          height="500"
          tooltip-effect="light"
          :header-cell-style="{ color: '#f5f5f5', background: '#23527c' }"
          :empty-text="loading ? '加载中...' : '暂无数据'"
               :summary-method="getSummaries"
    show-summary
        >
          <el-table-column label="#" width="35">
            <template slot-scope="scope">
              <el-checkbox></el-checkbox>
            </template>
          </el-table-column>
            <el-table-column
            prop="Duration"
            label="费用期间"
            width="100"
          />
            <el-table-column
            prop="CostCategoryName"
            label="费用项目"
            width="100"
          />
          <el-table-column
            prop="SubCostCategoryName"
            label="费用子项"
            width="100"
          />
          <el-table-column prop="Description" label="费用具体描述" width="120">
          </el-table-column>
          <el-table-column prop="ProjectName" label="项目名称" width="100" />
          <el-table-column prop="ShortProjectName" label="项目简称" width="80" />
          <el-table-column
            prop="SubProjectName"
            label="分项目名称"
            width="120"
          />
          <el-table-column prop="CompanyID" label="用工单位" width="80" >
              <template slot-scope="scope">
                {{scope.row.CompanyID | fromCompany(companyList)}}
              </template>
          </el-table-column>
          <el-table-column prop="EmploymentType" label="用工形式" width="80" >
              <template slot-scope="scope">
                {{scope.row.EmploymentType | fromEmployment(employmentList)}}
              </template>
          </el-table-column>
          <el-table-column prop="Sponsor" label="签证类型" width="80" />
          <el-table-column prop="Qties" label="人数" width="80" />
          <el-table-column prop="Amount" label="分割金额" width="80" />
          <el-table-column prop="ActualCost" label="实际成本" width="80" />
          <el-table-column prop="Difference" label="差额（收入）" width="120" />
          <el-table-column prop="Remark" label="备注" width="80" />
             <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <i
                class="fa fa-edit"
                @click="edit(scope.row)"
                v-if="powers.update"
              ></i>
              <i
                class="fa fa-trash"
                @click="deleteBatchCost(scope.row)"
                v-if="powers.delete"
              ></i>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          @size-change="getCostsList(1)"
          @current-change="getCostsList"
          :current-page.sync="page"
          :page-size.sync="size"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
       </div>
  
    </div>

    <el-dialog
      title="费用处理"
      :visible.sync="dialogVisible"
      width="99vw"
      :before-close="handleClose"
      top="1vh"
    >
      <div class="dialog-main"><CostChilden @close="handleClose" 
      @update="getCostsList" ref="costChilden"></CostChilden></div>
      <div class="dialog-footer">
        <el-button class="pop-close" @click="handleClose">关闭</el-button>
        <el-button
          class="pop-save"
          @click="save"
          >保存</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="导入费用处理"
      :visible.sync="dialogVisible3"
      width="600px"
      :before-close="handleClose3"
      top="50px"
      class="dialog"
    >

    <div class="text-box">
      <div class="text-title">模板文件</div>
      <div class="text-name" @click="getDownloadUrl">费用处理批量导入模板</div>
    </div>
      <div class="upload-box">
        <div class="left">
          <div v-if="name2" class="name">
             <svg-icon class="icon" icon-class="glyphicon-file"></svg-icon>
             {{name2}}
          </div>
        </div>
        <el-upload
          action="#"
          class="card-upload"
          :auto-upload="true"
          :http-request="uploadAttachment2"
          ref="uploadRef"
        >
          <button  class="btn-up"><svg-icon class="icon" icon-class="file"></svg-icon></svg>选择 ...</button>
        </el-upload>
      </div>
      <div class="dialog-footer2">
        <el-button class="pop-close" @click="handleClose3">关闭</el-button>
        <el-button class="pop-save pop-right" @click="addAttachment2">导入</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { auditRecords } from "@/utils/tableData";
import {
  getProjectAll,
  getCompanyAll,
  getDepartmentAll,
  getSaudiCostExport,
  getCostCategoryTree,
  getCostsList,
  getProjectsChildren,
  getEmploymentForm,
  getCostItem,
  deleteBatchCost,
  importCostTemplate,
  uploadAttachment,
  importCost,
  exportCost,
} from "@/api/user";
import CostChilden from "@/components/tables/costChilden.vue";
export default {
  filters: {
    fromCompany(id, data) {
      let name = "";
      data.forEach((item) => {
        if (item.ID === id) {
          name = item.CompanyCHS;
        }
      });
      return name;
    },
    fromEmployment(id, data) {
      let name = "";
      console.log(data, id);
      data.forEach((item) => {
        if (item.ID == id) {
          name = item.EmploymentName;
        }
      });
      return name;
    },
  },
  data() {
    return {
      data: [],
      active: [0],
      tableData: [],
      tableData2: [],
      multipleSelection: [],
      auditRecords: auditRecords,
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogType: 1, //1新建2、编辑
      options1: null,
      disabled: false,
      senior: false,
      invoiceNumber: null,
      costCenterCode: null,
      duration: null,
      projectId: null,
      companyId: null,
      deptId: null,
      projectList: [],
      companyList: [],
      departmentList: [],
      subProjectList: [],
      employmentList: [],
      value3: null,
      value4: null,
      value5: null,
      value6: null,
      radio: "1",
      statesRadio: "",
      textarea: "",
      roleName: "",
      enable: false,
      page: 1,
      size: 10,
      total: 10,
      Id: 0,
      ParentId: 0,
      DepartmentId: 0,
      powers: {
        add: false,
        update: false,
        delete: false,
        download: false,
        import: false,
        export: false,
        print: false,
        examine: false,
        upload: false,
        resetting: false,
      },
      name: null,
      EditState: 1,
      KeyID: null,
      FormID: null,
      categoryName: null,
      loading: true,
      name2: null,
      categoryId: null, //费用项目
      subCategoryId: null, //费用子项目
      projectId: null, //项目部
      subProjectId: null, //分项目部
      companyId: null, //用工单位,
      sponsor: null, //签证类型
      SponsorList: [
        {
          label: "SNEI",
          value: 1,
        },
        {
          label: "SEG",
          value: 2,
        },
        {
          label: "其它",
          value: 3,
        },
      ],
      allData: {
        Qties: 0,
        AllocationAmount: 0,
        ActualCost: 0,
        Difference: 0,
      },
      downloadUrl: null,
    };
  },
  components: { CostChilden },
  methods: {
    getButtonPower() {
      if (!this.$store.state.userPower) {
        this.$store.commit(
          "changeUserPower",
          JSON.parse(localStorage.getItem("userPower"))
        );
      }

      const item = this.$store.state.userPower.find((item) => {
        return item.Name === this.$route.meta.title;
      });
      const actions = item.Actions;

      actions.forEach((element) => {
        switch (element.Id) {
          case 1: //增加
            this.powers.add = true;
            break;
          case 2: //删除
            this.powers.delete = true;
            break;
          case 3: //修改
            this.powers.update = true;
            break;
          case 5: //下载
            this.powers.download = true;
            break;
          case 6: //导入
            this.powers.import = true;
            break;
          case 7: //导出
            this.powers.export = true;
            break;
          case 8: //打印
            this.powers.print = true;
            break;
          case 9: //审批
            this.powers.examine = true;
            break;
          case 10: //上传
            this.powers.upload = true;
            break;
          case 11: //重置
            this.powers.resetting = true;
            break;
        }
      });
    },
    //获取列表
    getCostsList(type) {
      if (type) {
        this.page = type;
      }
      this.loading = true;
      let startDate = null;
      let endDate = null;
      if (this.duration) {
        startDate = this.duration[0];
        endDate = this.duration[1];
      }
      const data = {
        page: this.page,
        size: this.size,
        startDate: startDate,
        endDate: endDate,
        categoryId: this.categoryId, //费用项目
        subCategoryId: this.subCategoryId, //费用子项目
        projectId: this.projectId, //项目部
        subProjectId: this.subProjectId, //分项目部
        companyId: this.companyId, //用工单位,
        sponsor: this.sponsor, //签证类型
      };
      getCostsList(data).then((res) => {
        if (res.status === 200) {
          this.tableData = res.response.data;
          this.total = res.response.dataCount;
          this.allData = res.extend;
        }
        this.loading = false;
      });
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (index === 11) {
          sums[index] = this.allData.Qties;
        } else if (index === 12) {
          sums[index] = this.allData.AllocationAmount;
        } else if (index === 13) {
          sums[index] = this.allData.ActualCost;
        } else if (index === 14) {
          sums[index] = this.allData.Difference;
        } else {
          sums[index] = " ";
        }
      });
      console.log("sums", sums);
      setTimeout(() => {
        this.$refs.table.doLayout();
      }, 100);
      return sums;
    },
    //导出
    getSaudiCostExport() {
      let startDate = null;
      let endDate = null;
      if (this.duration) {
        startDate = this.duration[0];
        endDate = this.duration[1];
      }
      const data = {
        startDate: startDate,
        endDate: endDate,
        categoryId: this.categoryId, //费用项目
        subCategoryId: this.subCategoryId, //费用子项目
        projectId: this.projectId, //项目部
        subProjectId: this.subProjectId, //分项目部
        companyId: this.companyId, //用工单位,
        sponsor: this.sponsor, //签证类型
      };
      exportCost(data).then((res) => {
        if (res.status === 200) {
          let url = res.response;
          let charIndex = url.indexOf("Download");
          url = url.substr(charIndex, url.length);
          url = url.replace(/\\/g, "/");
          location.href = "https://hrms.baishulai.com/" + url;
        }
      });
    },
    handleClose3() {
      this.dialogVisible3 = false;
      this.name2 = null;
    },
    uploadAttachment2(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix1 = img === "xls";
      const suffix2 = img === "xlsx";
      if (!suffix1 && !suffix2) {
        this.$message.error("只能上传后缀为xls、xlsx的文件!");
        return;
      }

      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.name2 = data.name;
          this.uploadUrl = res.response;
        }
      });
    },
    addAttachment2() {
      const data = {
        Path: this.uploadUrl,
        KeyID: this.KeyID,
      };
      importCost(data).then((res) => {
        if (res.status === 200) {
          this.$message.success("导入成功");
          this.handleClose3();
          this.getCostsList();
        }
      });
    },
    getDownloadUrl() {
      location.href = this.downloadUrl;
    },
    //新建
    newBuilt() {
      this.dialogVisible = true;
      this.dialogType = 1;
    },
    handleClose() {
      this.dialogVisible = false;
      this.$refs.costChilden.clearData();
    },
    save() {
      this.$refs.costChilden.addCostAllocation();
    },
    edit(data) {
      this.dialogVisible = true;
      getCostItem({ id: data.ID }).then((res) => {
        console.log(res);
        if (res.status === 200) {
          this.$refs.costChilden.geteditData(res.response);
        }
      });
    },
    //删除
    deleteBatchCost(row) {
      let ids = [];

      ids.push(row.ID);
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteBatchCost(ids).then((res) => {
          //
          if (res.status === 200) {
            this.$message.success("删除成功");
            this.getCostsList();
          }
        });
      });
    },
    //获取全部费用分类
    async getCostCategoryTree() {
      await getCostCategoryTree().then((res) => {
        if (res.status === 200) {
          var newArr = res.response.map((element, index) => {
            if (index === 0) {
              element.arrow = true;
            } else {
              element.arrow = false;
            }
            element.height = element.Children.length * 39 + 34;
            return element;
          });
          this.data = newArr;
          this.categoryId = this.data[0].ID;
          console.log("111", this.data);
        }
      });
    },
    changeArrow(index) {
      this.data[index].arrow = !this.data[index].arrow;
    },
    changeActive(index1, index2) {
      if (index2 || index2 === 0) {
        this.active = [index1, index2];
        this.categoryId = this.data[index1].ID;
        this.subCategoryId = this.data[index1].Children[index2].ID;
      } else {
        this.active = [index1];
        this.categoryId = this.data[index1].ID;
        this.subCategoryId = null;
      }
      this.getCostsList();
      console.log("111", this.active);
    },
    getProjectsChildren(id) {
      getProjectsChildren({ id }).then((res) => {
        if (res.status === 200) {
          this.subProjectList = res.response;
        } else {
          this.subProjectList = [];
        }
      });
    },
    changeProjectId() {
      console.log("11111");
      if (this.projectId) {
        this.getProjectsChildren(this.projectId);
      } else {
        this.subProjectList = [];
      }
      this.subProjectId = null;
    },
  },

  async created() {
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
    getDepartmentAll().then((res) => {
      if (res.status === 200) {
        this.departmentList = res.response;
      }
    });

    getEmploymentForm().then((res) => {
      if (res.status === 200) {
        this.employmentList = res.response;
      }
    });

    await this.getCostCategoryTree();
    this.getCostsList();
    this.getButtonPower();

    importCostTemplate().then((res) => {
      if (res.status === 200) {
        this.downloadUrl = "https://hrms.baishulai.com/" + res.response;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  background-color: #f7f8fa;
  .page-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .page-title {
      font-size: 18px;
      color: #000000;
    }
    .breadcrumb-active {
      color: #303133;
      font-weight: bolder;
      cursor: pointer;
      .fa {
        margin-right: 5px;
      }
    }
  }

  .card-box {
    width: 100%;
    height: calc(100% - 48px);
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    margin-top: 24px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .c-left {
      width: 222px;
      margin-right: 24px;
      background-color: #fff;
      height: 100%;
      flex-shrink: 0;
      .title-box {
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        background-color: #fff;
        font-size: 14px;
        font-weight: bolder;
        i {
          margin-right: 10px;
        }
      }
      .role-list {
        padding-top: 10px;
        // height: 500px;
        // overflow: auto;
        .role-item1 {
          margin-top: 5px;
          height: 34px;
          overflow: hidden;
          transition: height ease 0.75s;
        }
        .role-item2 {
          margin-top: 5px;
        }
        .role-fa {
          height: 500px;
          overflow: auto;
        }
        .role-title-box {
          display: flex;
          align-items: center;
          height: 34px;
          border-radius: 17px;
          padding: 0 10px;
          cursor: pointer;
          .arrow {
            flex-shrink: 0;
            width: 24px;
            height: 24px;
            cursor: pointer;
            transform: rotate(-90deg);
          }
          .arrow-active {
            transform: rotate(0deg);
          }
          .name {
            width: 100%; /* 设置一个固定的或最大宽度 */
            white-space: nowrap; /* 禁止文本换行 */
            overflow: hidden; /* 隐藏超出容器的文本 */
            text-overflow: ellipsis;
            color: #606266;
            font-size: 14px;
            line-height: 34px;
          }
        }
        .role-title-box2 {
          .name {
            width: 100%; /* 设置一个固定的或最大宽度 */
            white-space: nowrap; /* 禁止文本换行 */
            overflow: hidden; /* 隐藏超出容器的文本 */
            text-overflow: ellipsis;
            color: #606266;
            font-size: 14px;
            margin-left: 40px;
          }
        }
        .role-title-box:hover {
          background-color: #f2f2f2;
        }
        .role-title-box-active {
          background-color: #f2f2f2;
        }
      }
    }
    .c-right {
      width: 100%;
      background-color: #fff;
      height: 100%;

      .right-box {
        display: flex;
        justify-content: space-between;
        height: 40px;
        background-color: #f6faff;
        align-items: center;
        .title-box {
          height: 40px;
          display: flex;
          align-items: center;
          padding: 0 10px;
          font-size: 14px;
          font-weight: bolder;
          i {
            margin-right: 10px;
          }
        }
        .save {
          height: 30px;
          margin-right: 10px;
        }
      }
      .menu-box {
        min-height: calc(100% - 40px);
        .action-group {
          display: flex;
          // line-height: 32px;
          justify-content: center;
          align-items: center;
          label {
            float: left;
          }
          .action-text {
            line-height: 33px;
            label {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
  .card-box {
    width: 100%;
    // min-height: calc(100% - 48px);
    // height: calc(100% - 48px);
    background-color: #f7f8fa;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    margin-top: 24px;
    box-sizing: border-box;
    padding: 0 0 24px 0;
    .screen-box {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
      padding: 0 10px;
      .screen-input-box {
        margin-top: 24px;
      }
      .btn-pre {
        margin-top: 24px;
      }
      .btn-add {
        margin-top: 24px;
      }
      .el-input {
        width: 150px;
        margin-right: 24px;
      }
      .el-button {
        margin-right: 24px;
      }
      .el-select {
        width: 150px;
        margin-right: 24px;
      }
      .el-date-editor {
        margin-right: 24px;
      }
      .senior {
        position: absolute;
        width: 100%;
        top: 40px;
        left: 0;
        background: #fefefe;
        border: 1px solid #eae8e8;
        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: rgb(234, 232, 232);
        box-sizing: border-box;
        z-index: 999;
        padding: 0 24px 24px 24px;
        border-top: 0;
        box-shadow: 0 7px 18px -12px #bdc0bb;
        .from-boxs {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .from-box {
            margin-top: 24px;
            .name {
              margin-bottom: 10px;
            }
            .el-select {
              width: 150px;
            }
            .el-input {
              width: 150px;
            }
          }
        }
        .buttons {
          display: flex;
          justify-content: flex-end;
          margin-top: 24px;
        }
      }
    }
    .table-box {
      width: 100%;
      // height: calc(100% - 143px);
      .el-table {
        margin-top: 24px;
        // height: calc(100% - 56px);
        min-height: 500px;
        box-sizing: border-box;
      }
      .el-pagination {
        margin-top: 24px;
      }
      .table-color {
        color: #0076ff;
      }
      .buttons {
        display: flex;
      }
      .fa {
        margin-right: 10px;
        color: #3c8dbc;
        cursor: pointer;
      }
      .fa:hover {
        color: #72afd2;
      }
    }
  }
  .upload-box {
    display: flex;
    height: 34px;
    margin-top: 24px;
    .left {
      width: 100%;
      border: 1px solid #d2d6de;
      border-right: none;
      .name {
        display: flex;
        align-items: center;
        height: 34px;
        color: #555;
        font-size: 14px;
        text-indent: 2px;
      }
    }
    .btn-up {
      padding: 0;
      margin: 0;
      border: none;
      width: 90px;
      flex-shrink: 0;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      background-color: #3c8dbc;
      border: 1px solid #367fa9;
      color: #fff;
      font-size: 14px;
      .icon {
        margin-right: 5px;
      }
    }
    .disabled {
      cursor: no-drop;
    }
    .btn-up:hover {
      border-color: #204d74;
      background-color: #367fa9;
    }
  }
  .dialog-main {
    border-bottom: 1px solid #f4f4f4;
    padding: 12px 24px 0 24px;
    width: 100%;
    box-sizing: border-box;
    .line-box {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .from-box {
        display: flex;
        align-items: center;
        width: 330px;
        margin-bottom: 24px;
        .name {
          width: 80px;
          font-size: 16px;
          flex-shrink: 0;
        }
        .el-cascader {
          width: 250px;
        }
        .el-input {
          width: 250px;
        }
      }
    }
  }
  .dialog-footer {
    height: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .el-button {
      height: 34px;
      padding: 0 20px;
      margin-right: 12px;
      margin-left: 0;
    }
  }
}
.text-box {
  display: flex;
  color: #000000;
  .text-title {
    margin-right: 24px;
  }
  .text-name {
    color: #0076ff;
    cursor: pointer;
  }
}
.upload-box {
  display: flex;
  height: 34px;
  margin-top: 24px;
  .left {
    width: 100%;
    border: 1px solid #d2d6de;
    border-right: none;
    .name {
      display: flex;
      align-items: center;
      height: 34px;
      color: #555;
      font-size: 14px;
      text-indent: 2px;
    }
  }
  .btn-up {
    padding: 0;
    margin: 0;
    border: none;
    width: 90px;
    flex-shrink: 0;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #3c8dbc;
    border: 1px solid #367fa9;
    color: #fff;
    font-size: 14px;
    .icon {
      margin-right: 5px;
    }
  }
  .disabled {
    cursor: no-drop;
  }
  .btn-up:hover {
    border-color: #204d74;
    background-color: #367fa9;
  }
}
.dialog-footer2 {
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .el-button {
    height: 34px;
    padding: 0 20px;
    margin-right: 12px;
    margin-left: 0;
  }
}
</style>
<style lang="scss" scoped>
::v-deep .el-table {
  width: 100%;
  .el-table__header-wrapper table,
  .el-table__body-wrapper table {
    width: 100% !important;
  }
  .el-table__body,
  .el-table__footer,
  .el-table__header {
    table-layout: auto;
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
  .el-table__header {
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
}
</style>
<style scoped>
.el-dialog__wrapper >>> .el-dialog__body {
  padding: 0;
  overflow: auto;
  border-top: 1px solid #f4f4f4;
}
.el-dialog__wrapper >>> .my-label {
  background: #f5f7fa !important;
}
.el-textarea >>> .el-textarea__inner {
  min-height: 100px !important;
}
.el-table >>> .el-table__placeholder {
  width: 23px;
}
.table-box >>> .el-table__empty-block {
  width: 100% !important;
}
.el-table >>> th.el-table__cell > .cell {
  font-weight: bolder;
  font-size: 12px;
  text-align: center;
  font-family: "Microsoft YaHei";
}
.tableData2 >>> .el-table__header .cell {
  font-size: 14px !important;
  font-weight: bolder !important;
}

.tableData2 >>> .el-table__empty-block {
  width: 100% !important;
}

.el-table >>> th.el-table__cell {
  padding: 6px 0;
}

.el-table >>> .el-table__body .cell {
  text-align: center;
  font-size: 12px;
  color: #333;
}
.el-table >>> .el-table__cell {
  padding: 6px 0;
}
.approval {
  display: inline;
  padding: 2px 5px;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  font-size: 12px;
}
.label-success {
  background-color: #00a65a;
}
.label-warning {
  background-color: #f39c12;
}
.fa {
  margin-right: 10px;
  color: #3c8dbc;
  cursor: pointer;
}
.fa:hover {
  color: #72afd2;
}
.screen-box >>> .el-input__inner {
  height: 34px;
  border-color: #d2d6de;
  border-radius: 0;
}
.screen-box >>> .el-input__inner:focus {
  height: 34px;
  border-color: #61a2c9;
  border-radius: 0;
}
.card-upload >>> .el-upload-list {
  display: none;
}
.dialog >>> .el-dialog__body {
  padding: 15px 15px 0 15px;
}
.el-dialog__wrapper >>> .el-dialog__body {
  padding: 0;
  overflow: auto;
  border-top: 1px solid #f4f4f4;
}
.el-table >>> .el-table__footer-wrapper > .el-table__footer {
  width: 100% !important;
}
.el-dialog__wrapper >>> .my-label {
  background: #f5f7fa !important;
}
.el-textarea >>> .el-textarea__inner {
  min-height: 100px !important;
}
.el-table >>> .el-table__placeholder {
  width: 23px;
}
.table-box >>> .el-table__empty-block {
  width: 100% !important;
}
.el-table >>> th.el-table__cell > .cell {
  font-weight: bolder;
  font-size: 12px;
  text-align: center;
  font-family: "Microsoft YaHei";
}

.el-table >>> th.el-table__cell {
  padding: 6px 0;
}

.el-table >>> .el-table__body .cell {
  text-align: center;
  font-size: 12px;
  color: #333;
}
.el-table >>> .el-table__cell {
  padding: 6px 0;
}
.approval {
  display: inline;
  padding: 2px 5px;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  font-size: 12px;
}
.label-success {
  background-color: #00a65a;
}
.label-warning {
  background-color: #f39c12;
}
.fa {
  margin-right: 10px;
  color: #3c8dbc;
  cursor: pointer;
}
.fa:hover {
  color: #72afd2;
}
.screen-box >>> .el-input__inner {
  height: 34px;
  border-color: #d2d6de;
  border-radius: 0;
}
.screen-box >>> .el-input__inner:focus {
  height: 34px;
  border-color: #61a2c9;
  border-radius: 0;
}
.card-upload >>> .el-upload-list {
  display: none;
}
.dialog >>> .el-dialog__body {
  padding: 15px 15px 0 15px;
}
.tableData2 >>> .el-table__empty-block {
  width: 100% !important;
}
.card-select >>> .el-input__icon {
  line-height: 34px;
}
.table-box >>> .el-table__footer .el-table__cell {
  text-align: center;
}
</style>
